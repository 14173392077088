<template>
  <div class="numpad-panel">
    <div v-for="(row, rowIdx) in displayBtn" :key="rowIdx" class="flex" style="gap: 24px">
      <div
        v-for="item in row"
        :key="item.key"
        class="num-btn"
        :style="{ background: item.background, color: item.color }"
        @click="onBtnClick(item)"
      >
        <span v-if="item.type === 'text'">{{ item.text }}</span>
        <MaterialIcon v-if="item.type === 'icon'">{{ item.icon }}</MaterialIcon>
      </div>
    </div>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { computed, defineComponent, onBeforeUnmount, onMounted } from 'vue'
import hotkeys from 'hotkeys-js'

export default defineComponent({
  name: 'NumPadPanel',
  props: {
    value: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
    },
  },
  setup (props, { emit }) {
    const syncValue = useVModel(props, 'value', emit)
    const displayBtn = computed(() => {
      return [
        [
          { text: '1', value: '1', type: 'text', key: 'num_1,1' },
          { text: '2', value: '2', type: 'text', key: 'num_2,2' },
          { text: '3', value: '3', type: 'text', key: 'num_3,3' },
        ],
        [
          { text: '4', value: '4', type: 'text', key: 'num_4,4' },
          { text: '5', value: '5', type: 'text', key: 'num_5,5' },
          { text: '6', value: '6', type: 'text', key: 'num_6,6' },
        ],
        [
          { text: '7', value: '7', type: 'text', key: 'num_7,7' },
          { text: '8', value: '8', type: 'text', key: 'num_8,8' },
          { text: '9', value: '9', type: 'text', key: 'num_9,9' },
        ],
        [
          { icon: 'refresh', value: 'restart', type: 'icon', key: 'esc', background: 'var(--primary-100)', color: 'white' },
          { text: '0', value: '0', type: 'text', key: 'num-0,0' },
          { icon: 'arrow_back', value: 'backspace', type: 'icon', key: 'delete', background: 'var(--primary-100)', color: 'white' },
        ],
      ]
    })

    const onBtnClick = (item) => {
      if (item.key.startsWith('num')) {
        if (props.maxLength && syncValue.value.length >= props.maxLength) return
        syncValue.value += item.value.toString()
      } else if (item.key === 'esc') {
        syncValue.value = ''
        emit('clear')
      } else if (item.key === 'delete') syncValue.value = syncValue.value.slice(0, syncValue.value.length - 1)
    }

    const registerBtnKey = () => {
      const btns = displayBtn.value.flat()
      btns.forEach((btn) => {
        hotkeys(btn.key, 'numpadPanel', function (event, handler) {
          event.preventDefault()
          onBtnClick(btn)
        })
      })
    }

    const unregisterBtnKey = () => {
      hotkeys.deleteScope('numpadPanel')
      const btns = displayBtn.value.flat()
      btns.forEach((btn) => {
        hotkeys.unbind(btn.key)
      })
    }

    onMounted(() => {
      registerBtnKey()

      hotkeys.setScope('numpadPanel')
    })

    onBeforeUnmount(() => {
      unregisterBtnKey()
    })

    return { syncValue, onBtnClick, displayBtn }
  },
})
</script>

<style lang="postcss" scoped>
.numpad-panel {
  @apply flex flex-col gap-[24px];
}
.num-btn {
  @apply border-[2px] border-gray-20 cursor-pointer select-none;
  @apply w-[80px] h-[80px] rounded-[6px];
  @apply flex items-center justify-center gap-[24px];
  @apply text-primary-100 text-[24px];
  font-weight: 900;
}
</style>
